$white: #fff;
$off-white: #dfdfdf;
$light-white: #f5f5f5;
$gray: #d8d8d8;

$off-black: #49454f;
$black: #000000;

$green: #3fc063;
$light-green: #4cd964;
$off-grren: #3bb273;

$golden-yellow: #ee9500;
$orange: #ef9621;
$blue: #0061fe;
$danger: #f44336;
$error: #ff3b30;
$disable: #bfbfbf;
$drop-down-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

$light-gray: #eef2f6;
