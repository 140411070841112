// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@import '~quill/dist/quill.snow.css';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
@import './common.scss';
@import './variable.scss';
@import './material.scss';
@import 'intl-tel-input/build/css/intlTelInput.css';

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$webapp-primary: mat.define-palette(mat.$indigo-palette);
$webapp-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$webapp-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$webapp-theme: mat.define-light-theme(
    (
        color: (
            primary: $webapp-primary,
            accent: $webapp-accent,
            warn: $webapp-warn,
        ),
    )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($webapp-theme);

/* You can add global styles to this file, and also import other style files */
:root {
    --register-ticket-theme: #f5f5f5;
    --register-ticket-theme-remaining-bg: rgba(105, 190, 40, 0.1);
    --event-feedback-theme: #69be28;
    --skeleton-ui-background: #f5f5f5;
    --live-interactivity-theme: #f5f5f5;
    --af-button-color: #0061fe;
    --af-background-color: #ffffff;
    --af-text-color: #000000;
    --af-modal-background-color: #f5f5f5;
    --af-button-text-color: #ffffff;
    --af-link-color: #69be28;
    --af-element-border-radius: 10px;
    --af-button-border-radius: 5px;
    --af-nps-border-radius: 5px;
    --af-rounded-control-padding: 0;
    --af-rounded-down-arrow-padding: 0;
    --af-isknown--container-padding: 10px;
    --af-header-font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
        sans-serif;
    --af-header-font-weight: 700;
    --af-overlay-text-color: #ffffff;
    --af-border-bottom-color: #d9d9d9;
    --af-input-tel-arrow-down: %23000000;
    --af-time-picker-background-color: #ffffff;
}

html,
body {
    height: 100%;
    box-sizing: border-box;
    font-size: 15px;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

*,
body {
    margin: 0;
    font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif,
        'Inter' !important;
    // font-size: 15px;

    button {
        font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
            sans-serif !important;
    }
}

@media screen and (-webkit-min-device-pixel-ratio: 2), screen and (min-resolution: 2dppx) {
    body {
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
    }
}
