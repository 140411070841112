@import './variable.scss';

@font-face {
    font-family: 'Inter';
    // src/assets/fonts/Inter/Inter-VariableFont_opsz,wght.ttf
    src: url('/assets/fonts/Inter/Inter-VariableFont_opsz,wght.ttf') format('truetype');
}

.event-img {
    width: 50px;
    height: 50px;
    border-radius: 10px;
    background: $light-white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 16px;
}

.page-wrapper {
    max-width: 600px;
    margin: 0 auto 50px;

    .title-container {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        h1 {
            font-weight: 700;
            font-size: 25px;
            line-height: 37px;
            color: $black;
            margin-right: 29px;
        }
    }
}

.container-instructions {
    margin-bottom: 30px;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: $black;
    opacity: 0.5;
}

.text {
    line-height: 150%;
    color: black;

    &.text-18 {
        font-size: 18px;
        line-height: 150%;
    }

    &.text-40 {
        font-size: 40px;
        line-height: 150%;
    }

    &.text-19 {
        font-size: 19px;
        line-height: 150%;
    }

    &.text-25 {
        font-size: 25px;
        line-height: 150%;
    }

    &.text-15 {
        font-size: 15px;
        line-height: 150%;
    }

    &.text-11 {
        font-size: 11px;
        line-height: 150%;
    }

    &.text-12 {
        font-size: 12px;
        line-height: 150%;
    }

    &.text-13 {
        font-size: 13px;
        line-height: 150%;
    }

    &.text-14 {
        font-size: 14px;
        line-height: 150%;
    }

    &.text-16 {
        font-size: 16px;
        line-height: 150%;
    }

    &.text-20 {
        font-size: 20px;
        line-height: 150%;
    }

    &.text-22 {
        font-size: 22px;
        line-height: 150%;
    }

    &.text-24 {
        font-size: 24px;
        line-height: 150%;
    }

    &.text-30 {
        font-size: 30px;
        line-height: 150%;
    }

    &.text-35 {
        font-size: 35px;
        line-height: 150%;
    }

    &.text-31 {
        font-size: 31px;
        line-height: 150%;
    }

    &.text-32 {
        font-size: 32px;
        line-height: 150%;
    }

    &.text-37 {
        font-size: 37px;
        line-height: 150%;
    }

    &.text-38 {
        font-size: 38px;
        line-height: 150%;
    }

    &.bold {
        font-weight: 700 !important;
    }

    &.normal {
        font-weight: 400;
    }

    &.medium {
        font-weight: 500;
    }

    &.text-center {
        width: 100%;
        text-align: center;
    }

    &.opacity-half {
        opacity: 0.5;
    }

    &.line-height-100 {
        line-height: 100%;
    }

    &.line-height-120 {
        line-height: 120%;
    }

    &.line-height-125 {
        line-height: 125%;
    }

    &.uppercase {
        text-transform: uppercase;
    }

    &.text-red {
        color: #f44336;
    }

    &.text-white {
        color: #fff;
    }

    &.text-blue {
        color: #0061fe;
    }

    &.text-hover {
        cursor: pointer;
    }
    &.text-light {
        color: #808080;
    }
}

.add-btn {
    display: flex;
    align-items: center;
    margin-left: 10px;

    &.btn-publish {
        .btn-add-event {
            background: $light-green;
        }
    }

    &.btn-orange {
        .btn-add-event {
            background: $orange;

            .mdc-button__label {
                font-weight: 400;
            }
        }
    }

    .btn-add-event {
        &.mat-mdc-button.mat-mdc-button-base {
            height: 37px;
            padding: 7px 10px;
        }

        &.spin-btn {
            min-width: 113px;
        }

        &.QRcode-btn {
            min-width: 136px;
        }

        // width: 116px;
        // height: 37px;
        background: $blue;
        border-radius: 4px;
        font-weight: 700;
        font-size: 15px;
        line-height: 100%;
        // color: $white;
        padding: 7px 10px;

        &.btn-cancel {
            background-color: $error;
        }

        &.spinner-cancel {
            min-width: 102px;
        }

        &.btn-publish-spinner {
            min-width: 73px;
        }

        .btn-delete-question {
            background-color: $white;
            color: #ff3b30;
        }

        &.btn-close {
            background-color: $light-white;
            color: $black;

            .mdc-button__label {
                color: $black;
                font-weight: 400;

                svg-icon {
                    svg {
                        path {
                            fill: $black;
                        }
                    }
                }
            }
        }

        .mdc-button__label {
            display: flex;
            color: $white;
            display: flex;
            align-items: center;

            svg-icon {
                &.button-text-action {
                    display: flex;
                    margin-left: 8px;
                    align-items: center;
                    margin-right: 0;
                }

                svg {
                    path {
                        fill: $white;
                    }

                    //     }
                    // &.remove {
                    //     svg {
                    //         path {
                    //             fill: $white;
                    //         }
                    //     }
                    // }
                }
            }
        }
    }
}

.ql-image {
    display: none;
}

.page-form {
    .form-session {
        margin-bottom: 15px;

        .session-title {
            font-weight: 700;
            font-size: 18px;
            line-height: 150%;
            color: $black;
            margin-bottom: 11px;

            &.session-margin {
                margin-bottom: 15px;
            }
        }
    }
}

.switch-container {
    display: flex;
    align-items: center;
    gap: 7px;

    h4 {
        width: 100%;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
    }
}

.info-title-wrapp {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 11px;

    h5 {
        font-weight: 700;
        font-size: 18px;
        line-height: 150%;
    }

    .icon-container {
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;

        p {
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 150%;
            color: $blue;
        }

        svg-icon {
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.uploader-input {
    display: none;
}

.title-text {
    font-weight: 700;
    font-size: 25px;
    line-height: 38px;
    color: $black;
    border-bottom: 1px dashed transparent;
    min-width: 110px;
}

.page-title-container {
    display: flex;
    align-content: baseline;

    svg-icon {
        width: 38px;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
        cursor: pointer;

        &.remove-icon {
            margin-left: 0;
        }

        &:hover {
            background-color: $light-white;
            border-radius: 4px;
        }
    }
}

.transparent-input {
    border: none;
    outline: none;
    padding: 0px;
    font-weight: 700;
    font-size: 25px;
    line-height: 38px;
    color: $black;
    // margin-right: 10px;
    border-bottom: 1px dashed black;
    // max-width: 210px;
    min-width: 110px;
    width: 50px;
    display: flex;
    justify-content: flex-start;

    &.rm-border {
        border-color: transparent;
    }
}

.input-wrapper {
    display: flex;
    align-items: center;
    background: #fff;
}

.input-sizer {
    display: inline-grid;
    vertical-align: top;
    align-items: center;
    position: relative;
    padding: 0;

    &.stacked {
        padding: 0.5em;
        align-items: stretch;

        &::after,
        input,
        textarea {
            grid-area: 2 / 1;
        }
    }

    &::after,
    input {
        width: 100%;
        min-width: 1rem;
        grid-area: 1 / 2;
        font: inherit;
        padding: 0.25em 0.25em 0.25em 0;
        margin: 0;
        resize: none;
        background: none;
        appearance: none;
        outline: none;
        box-shadow: none;
        border: none;
        font-weight: 700;
        font-size: 25px;
        line-height: 38px;
        height: 38px;
        color: $black;
        border-bottom: 1px dashed black;

        &:focus-within,
        &:focus {
            outline: none;
        }
    }

    span {
        padding: 0.25em;
    }

    &::after {
        // width: auto;
        // min-width: 110px;
        content: attr(data-value) ' ';
        visibility: hidden;
        white-space: pre-wrap;
    }
}

.rm-border {
    border-color: transparent !important;
}

.counter {
    width: 29px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $off-white;
    border-radius: 40px;
    margin: 0 0 0 12px;

    span {
        font-weight: 400;
        font-size: 10px;
        line-height: 150%;
        color: $black;
    }
}

.input-sizer {
    > span {
        text-transform: uppercase;
        font-size: 0.8em;
        font-weight: bold;
        text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.15);
    }
}

.header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
    height: 70px;
    background: $white;
    border-bottom: 1px solid $off-white;
    position: sticky;
    top: 0;
    z-index: 9;

    &.auth-header {
        border-bottom: none;
    }

    .event-logo {
        max-width: 170px;
        height: 37px;

        svg-icon {
            display: flex;
            cursor: pointer;

            svg {
                height: 100%;
            }
        }
    }
}

// AUTH COMMON STYLE

.auth-btn {
    margin-top: 10px;

    &.sign-in-btn {
        button {
            background: $white;

            .mdc-button__label {
                color: $black;
                font-weight: normal;
            }
        }
    }

    button {
        width: 100%;
        max-height: 50px;
        height: 50px;

        display: flex;
        align-items: center;
        justify-content: center;
        background: $black;

        border-radius: 4px;

        .mdc-button__label {
            font-weight: 700;
            font-size: 15px;
            line-height: 100%;
            color: $white;
            text-transform: capitalize;
        }
    }

    .mat-mdc-button.mat-mdc-button-base {
        height: 50px;
        max-height: 50px;
    }
}

// .header-container {

//     border-bottom: none;
// }

.page-content-wrapp {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    // height: calc(100% - 70px);
    height: calc(100%);
    margin-top: -70px;

    .content-wrap {
        max-width: 400px;
        width: 100%;
    }
}

.main-text {
    font-weight: 700;
    font-size: 25px;
    line-height: 150%;
    margin-bottom: 10px;
}

.info-text,
.option-text {
    font-weight: 400;
    font-size: 15px;
    line-height: 150%;
    opacity: 0.5;
}

.auth-content-margin {
    margin-top: 20px;
}

// PASSWORS STRENGTH-METER CSS
.pwd-match {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 22px;
    margin-top: -20px;
    align-items: center;

    &.margin-top {
        margin-top: -10px;
    }

    .strength-message {
        display: flex;
        align-items: center;
        font-size: 13px;
        color: #ea4e2c;
        line-height: 100%;
        gap: 6.5px;

        svg-icon {
            display: flex;
            margin-right: 3.6px;
            width: 18px;
            height: 18px;
            align-items: center;
            justify-content: center;
        }

        &.strong-password {
            color: $light-green;
        }

        &.can-be-strong-password {
            color: #ee9500;

            svg-icon {
                svg {
                    path {
                        fill: #ee9500;
                    }
                }
            }
        }
    }

    mat-password-strength {
        max-width: 175px;
        width: 100%;
        height: 5px;
        position: relative;

        .mat-mdc-progress-bar {
            width: 100%;
            height: 100%;

            &.mat-warn {
                .mdc-linear-progress__primary-bar,
                .mdc-linear-progress__secondary-bar {
                    .mdc-linear-progress__bar-inner {
                        border-color: #ea4e2c;
                    }
                }
            }

            &.mat-accent {
                .mdc-linear-progress__primary-bar,
                .mdc-linear-progress__secondary-bar {
                    .mdc-linear-progress__bar-inner {
                        border-color: #ee9500;
                    }
                }
            }

            &.mat-primary {
                .mdc-linear-progress__primary-bar,
                .mdc-linear-progress__secondary-bar {
                    .mdc-linear-progress__bar-inner {
                        border-color: $light-green;
                    }
                }
            }

            .mdc-linear-progress__buffer-bar {
                background-color: #d9d9d9;
            }
        }

        // mat-mdc-progress-bar mdc-linear-progress mat-warn mdc-linear-progress--animation-ready
    }

    .bar-items {
        display: flex;
        position: absolute;
        max-width: 175px;
        max-height: 5px;
        width: 100%;
        height: 100%;
        background-color: transparent;

        &::after {
            position: absolute;
            content: '';
            max-width: 175px;
            max-height: 5px;
            width: 100%;
            height: 100%;
            background-color: $white;
            z-index: -1;
        }

        .bar-item {
            width: 35px;
            height: 100%;
            border-right: 4px solid $white;
            // border-radius: 6px;
        }
    }
}

// list-component CSS

.list-wrapper {
    padding-left: 16px;
}

.list-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    .page-title-container {
        display: flex;
        flex: 1;
        justify-content: flex-start;
    }
}

.list-feature-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
    // gap: 10px;

    .feature-icon {
        width: 37px;
        height: 37px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
            background-color: $light-white;
        }
    }
}

.list-table {
    overflow-x: auto;
    overflow-y: hidden;
    border: 1px solid $off-white;
    border-radius: 10px;

    &.list-hidden {
        border: 0;
    }
}

// session CSS

.session-wrapp {
    display: flex;
    max-width: 940px;
    margin: 0 auto;
    gap: 50px;
    flex-direction: column;
}

.title-text {
    line-height: normal;
}

.sesssion-flex {
    display: flex;
    justify-content: space-between;
}

.session-container-left {
    max-width: 600px;
    width: 100%;
    margin: 0;

    .session-icon-wrapp {
        display: flex;

        svg-icon {
            display: flex;
            align-items: center;
            cursor: pointer;
            justify-content: center;
            width: 24px;
            height: 24px;
        }
    }
}

.field-container {
    .field {
        display: flex;
        width: 100%;
        gap: 30px;

        .disable {
            pointer-events: none;
        }
    }

    app-auto-complete {
        flex: 1;
    }

    app-input {
        flex: 1;
    }

    app-input-phone {
        flex: 1;
    }

    app-time-picker {
        flex: 1;
    }

    app-datepicker {
        flex: 1;
    }

    .session-icon-container {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 13px 10px;
        border-radius: 5px;
        background: $light-white;
        max-height: 50px;
        max-width: 285px;
        cursor: pointer;

        .icon-container {
            gap: 10px;
        }
    }
}

.document {
    display: flex;
    align-items: center;
    // gap: 15px;
    justify-content: space-between;
    background-color: $light-white;
    padding: 10px;
    height: 50px;
    border-radius: 4px;

    &:hover {
        .remove-container {
            display: flex;
            align-items: center;
            margin-left: auto;

            .separator {
                height: 25px;
                width: 1px;
                margin: 0 14px;
                background: $off-white;
            }
        }

        p.name {
            max-width: 163px;
        }
    }

    .div-end-container {
        display: flex;
        align-items: center;
    }

    .doc-container,
    .profile-container {
        display: flex;
        align-items: center;
        gap: 15px;
    }

    .color-block {
        width: 24px;
        height: 24px;
        background-color: red;
        border-radius: 4px;
    }

    .remove-container {
        display: none;

        svg-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            cursor: pointer;
        }
    }

    &.document-bottom {
        p {
            line-height: normal;
            height: auto;
        }
    }

    line-height: 100%;

    svg-icon {
        display: flex;
    }

    p {
        font-weight: 400;
        font-size: 15px;
        line-height: 50px;
        max-width: 232px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
        height: 50px;
        // min-width: 177px;

        &.speaker-name {
            max-width: 220px;
        }

        &.opacity {
            opacity: 0.5;
            margin-top: 2px;
            font-size: 13px;
        }
    }

    .session-details {
        p {
            font-weight: 400;
            font-size: 15px;
            line-height: unset;
            height: auto;
        }
    }
}

.add-icon-wrapper {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 5px;

    &.disable {
        pointer-events: none;
    }

    .custom-speaker {
        position: absolute;
        right: 0;
        top: 40px;
        z-index: 9;
        background: #fff;
    }
}

.session-container-right {
    max-width: 290px;
    width: 100%;
}

.form-fields-container {
    // padding: 10px;
    background: transparent;

    &.custom-field,
    &.labels-detail {
        display: flex;
        flex-direction: column;
        margin-bottom: 24px;
        gap: 20px;
        padding: 15px 10px;
        background: $light-white;
        border-radius: 4px;
    }

    &.field-margin {
        margin-bottom: 35px;
    }

    &.labels-detail {
        background: transparent;
        border: 1px dashed $off-white;
        gap: 12px;
    }

    &.cursor-pointer {
        cursor: pointer;
    }

    .container-instructions {
        margin-bottom: 0;
    }
}

.uploader {
    margin-top: 20px;
    // width: 600px;
    height: 70px;
    border: 1px dashed $black;
    border-radius: 4px;
    display: flex;
    padding: 10px;
    cursor: pointer;

    .uploader-img {
        margin: 0;
    }
}

.uploader-instruction {
    margin-left: 37px;

    p {
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
        color: $black;
        margin: 0;
    }
}

.loader-icon-sessions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    // width: 31px;
    // height: 31px;
    // transform: scaleX(-1);
    border-radius: 50%;
    animation-name: session-rotater;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes session-rotater {
    from {
        // transform: scaleX(-1);
        transform: scaleX(-1) rotate(360deg);
    }

    to {
        // transform: scaleX(-1);
        transform: scaleX(-1) rotate(-360deg);
    }
}

.labels-detail-document {
    padding: 8px 10px;
    gap: 10px;
    border: 1px dashed $off-white;
    display: flex;
    flex-direction: column;
}

.doc {
    position: relative;

    mat-error {
        position: absolute;
        bottom: -18px;
        font-size: 12px;
    }
}

.image-feature-conatiner {
    display: flex;
    align-items: center;
    gap: 5px;

    svg-icon {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;

        cursor: pointer;
    }
}

.quill-margin {
    margin-top: 30px;
}

.modal-container {
    max-width: 480px;
    max-height: 280px;
    width: 100%;
    height: 100%;
    padding: 30px;

    .field {
        margin-top: 30px;
    }

    .add-btn {
        justify-content: flex-end;
        margin-left: 0;
    }
}

.promote-flex {
    display: flex;
    // justify-content: space-between;
    width: 100%;
}

.btn-container-delete {
    .btn {
        &.mat-mdc-button.mat-mdc-button-base {
            height: 37px;
            padding: 7px 10px;
        }

        background: $blue;
        border-radius: 4px;
        font-weight: 400;
        font-size: 15px;
        line-height: 100%;
        // color: $white;
        padding: 7px 10px;
        border: 1px solid transparent;

        .mdc-button__label {
            display: flex;
            // color: $white;

            svg-icon {
                &.button-text-action {
                    display: flex;
                    margin-left: 8px;
                    align-items: center;
                    margin-right: 0;
                }
            }
        }

        &.btn-delete-question {
            background-color: $white;
            color: #ff3b30;
            border-radius: 4px;
            border: 1px solid #ff3b30;
        }
    }
}

.btn-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    margin-top: 30px;

    .btn {
        &.mat-mdc-button.mat-mdc-button-base {
            height: 37px;
            padding: 7px 10px;
        }

        background: $blue;
        border-radius: 4px;
        font-weight: 700;
        font-size: 15px;
        line-height: 100%;
        // color: $white;
        padding: 7px 10px;
        border: 1px solid transparent;

        .mdc-button__label {
            display: flex;
            // color: $white;

            svg-icon {
                &.button-text-action {
                    display: flex;
                    margin-left: 8px;
                    align-items: center;
                    margin-right: 0;
                }
            }
        }

        &.btn-cancel {
            background-color: $light-white;
            color: $black;
        }

        &.btn-delete-question {
            background-color: $white;
            color: #ff3b30;
            border-radius: 4px;
            border: 1px solid #ff3b30;
        }

        &.btn-delete {
            background-color: $error;
            color: $white;
        }

        &.btn-done {
            background-color: $blue;
            color: $white;
        }

        &.spinner-time-shift {
            min-width: 78px;
        }
    }
}

.field-container {
    .field {
        .question-type-container {
            min-width: 150px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 3px 10px;
            gap: 10px;
            height: 50px;
            background: $light-white;
            border-radius: 5px;
            cursor: pointer;

            .type-container {
                display: flex;
                align-items: center;
                // padding: 10px;
                gap: 10px;

                // p {
                //     line-height: 100%;
                // }
            }

            svg-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 24px;
                height: 24px;
            }
        }
    }
}

.types-list {
    display: flex;
    align-items: center;
    gap: 20px;

    svg-icon {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.type-menu .mat-mdc-menu-content:not(:empty) .mat-mdc-menu-item {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
}

.mat-mdc-menu-panel.mat-mdc-menu-panel.type-menu {
    min-width: 150px;
    max-width: 150px;
    width: 150px;
    padding: 5px;

    .mat-mdc-menu-item .mdc-list-item__primary-text {
        width: 100%;
    }
}

.add-choice-container {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    // max-width: 185px;
    width: fit-content;

    svg-icon {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            path {
                fill: #0061fe;
            }
        }
    }

    .add-msg {
        color: $blue;
        // line-height: 100%;
    }
}

// .hidden-class {
//     display: fixed;
//     z-index: -1;
// }

.qr-code-conatiner {
    width: 1412px;
    height: 2000px;

    // width: 100%;
    // height: 100%;
    background: #635bff;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // visibility: hidden;
    position: fixed;
    overflow: hidden;
    z-index: -1;
    // opacity: 0;

    .canvas {
        padding: 80px;
    }

    h4 {
        font-weight: 700;
        font-size: 270px;
        line-height: 150%;
        text-align: center;
        color: $white;
    }
}

.info-container {
    margin-top: 10px;
}

.sigin-options {
    // margin-top: 20px;
    display: flex;
    align-items: center;
    gap: 20px;

    .option {
        display: flex;
        align-items: center;
        padding: 13px 10px;
        max-height: 50px;
        height: 100%;
        flex: 1;
        border: 1px solid $black;
        border-radius: 4px;
        justify-content: center;

        gap: 10px;
        cursor: pointer;

        &.disabled {
            pointer-events: none;
        }

        svg-icon {
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .option-text {
            opacity: 1;
        }
    }
}

.page-separator {
    display: flex;
    align-items: center;
    justify-content: center;

    max-height: 33px;
    height: 100%;

    span {
        font-weight: 400;
        font-size: 13px;
        line-height: 100%;
        color: #808080;
        position: relative;
        width: 100%;
        text-align: center;

        &::before {
            content: '';
            width: 100%;
            background-color: $off-white;
            height: 1px;
            justify-content: flex-end;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            z-index: 0;
        }

        p {
            display: inline-flex;
            background: $white;
            position: relative;
            padding: 10px;
            // width: 39px;
            justify-content: center;
        }
    }
}

.build-header {
    display: flex;
    align-content: center;
    justify-content: space-between;
    height: 70px;
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid $off-white;
    position: sticky;
    top: 0;
    background: $white;
    z-index: 9;

    .event-details {
        display: flex;
        align-items: center;

        .event-img {
            margin: 0 20px 0 0;
            overflow: hidden;

            &:hover {
                background: $white;

                picture {
                    display: none;
                }

                .no-image-container {
                    display: none;
                }

                .back-arrow {
                    display: flex;
                    width: 50px;
                    height: 50px;
                    align-items: center;
                    justify-content: center;

                    cursor: pointer;
                }
            }

            .back-arrow {
                display: none;
            }

            picture {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        h3 {
            font-weight: 700;
            font-size: 25px;
            line-height: 70px;
            color: #d9d9d9;

            &.opacity {
                color: $black;
            }
        }
    }

    .close-icon {
        cursor: pointer;
        width: 40px;
        height: 40px;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:hover {
            background: $light-white;
        }
    }
}

.audience-status {
    padding: 13px;
    border-radius: 18px;
    font-size: 13px;
    font-weight: 400;
    color: white;
    max-height: 26px;
    height: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;

    &.active {
        background-color: #3fc063;
    }

    &.bounce {
        background-color: #f04a44;
    }

    &.spam {
        background-color: #ef9621;
    }

    &.unsubscribed {
        background-color: #969696;
    }

    &.no-status {
        background-color: #e9edf1;
    }
}

.icon-hover {
    width: 37px;
    height: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
        background-color: $light-white;
    }

    &.import-icon {
        width: 24px;
        height: 24px;

        &:hover {
            background-color: transparent;
        }

        .active {
            svg {
                path {
                    fill: $white;
                }
            }
        }

        // svg-icon {}
    }

    svg-icon {
        display: flex;
    }
}

.green-lines {
    display: flex;
    gap: 20px;
    margin-bottom: 36px;
    width: 620px; //clear

    .line {
        height: 2px;
        border-radius: var(--af-element-border-radius);
        width: 100%;
        background-color: var(--af-modal-background-color);
        height: 6px;
        // width: 280px; //change 180px
        width: 50%;

        &.active {
            background-color: var(--af-button-color);
        }
    }
}

@media screen and (max-width: 991px) {
    .green-lines {
        width: 100% !important;

        .line {
            width: 100%; //change 180px

            &.active {
                background-color: var(--af-button-color);
            }
        }
    }
}

.info-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    border-radius: 10px;
    border: 1px solid #0061fe;
    background: rgba(0, 97, 254, 0.1);
    margin-bottom: 30px;

    .wrapper-icon {
        display: flex;
        gap: 10px;
        align-items: center;
        p {
            color: #0061fe;
        }

        svg-icon {
            svg {
                path {
                    fill: #0061fe;
                }
            }
        }
    }

    .btn-container {
        width: auto;
        justify-content: flex-start;
        margin-top: 0;
        cursor: pointer;
        width: fit-content;

        p {
            color: #0061fe;
        }
    }
}

.img-skeleton-container {
    display: flex;
    width: 70px;
    height: 70px;
    padding: 23px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 5px;
    background: #f5f5f5;
}

@media only screen and (max-width: 500px) {
    .pre-checkout-modal__content {
        button {
            display: block !important;
        }
        position: unset !important;
        width: 85% !important;
    }
}
